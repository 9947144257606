var CONSENT_EVENT = 'adobePrivacy:PrivacyConsent';

// Currently, the consent check is disabled by default and needs to be enabled
// in the HTML after the flipper flag has been set to true.
var IS_CONSENT_CHECK_ENABLED = false;

/**
 * Define the options available for loading a resource.
 *
 * @typedef {Object} Typekit.AdobePrivacy.Config
 * @param {String} config.path Absolute or relative path to the resource
 * @param {String} config.type Type of asset. It can be script or style
 * @param {Boolean} config.async Loads the script async. Will be ignored if the resource is style
 * @param {String} config.id The id for the asset
 * @param {Function} config.callback The callback to be executed after the asset is loaded
 */

/**
 * Execute the callback after displaying Adobe's privacy consent dialog.
 * @param {Function} callback
 */
export function afterConsent(callback) {
  // If the consent check is enabled, we will need to wait for a consent check
  // before executing the callback, otherwise, execute the callback right away.
  if (IS_CONSENT_CHECK_ENABLED) {
    window.addEventListener(CONSENT_EVENT, function() {
      callback();
    });
  } else {
    callback();
  }
}

/**
 * Enable the consent check.
 */
export function enableConsentCheck() {
  IS_CONSENT_CHECK_ENABLED = true;
}

/**
 * Loads an asset (script or style) based on a configuration object.
 * See more documentation about the underlying method here:
 *   https://wiki.corp.adobe.com/pages/viewpage.action?pageId=1470496401
 *
 * @param {Typekit.AdobePrivacy.Config} config
 */
export function loadResource(config) {
  if (window.adobePrivacy) {
    window.adobePrivacy.loadResource(config);

  // If the Adobe Privacy Resource loader doesn't exist, load the resource
  // ourselves.
  } else {
    var scriptTag = document.createElement('script');
    scriptTag.src = config.path;
    if (config.callback) {
      scriptTag.onload = config.callback;
    }

    document.head.appendChild(scriptTag);
  }
}
